import { React, styled } from 'x'
import { useEffect, useState, useRef } from 'hooks'
import { innoImg } from 'library'

const Player = styled.div``

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    let script = document.createElement('script')
    script.src = src
    script.onload = resolve
    script.onerror = reject
    document.body.appendChild(script)
  })

const PlayerBlock = (props) => {
  const { videoId, previewImage } = props
  const playerEl = useRef(null)

  // const toggleP2P = (e) => {
  //   e.preventDefault()
  //   if (typeof window !== 'undefined') {
  //     let method = P2PEnabled ? 'setItem' : 'removeItem'
  //     let params = ['disable-p2p']
  //     if (P2PEnabled) {
  //       params.push(true)
  //     }
  //     window.localStorage[method](...params)
  //     window.location.reload()
  //   }
  // }

  // useEffect(() => {
  //   if (
  //     typeof window !== 'undefined' &&
  //     window.location.search.indexOf('debug') !== -1
  //   ) {
  //     setShowDebug(true)
  //   }
  // }, [])

  // useEffect(() => {
  //   const onPlay = () => {
  //     window.fathom && window.fathom.trackGoal('BJALWDMV', 0)
  //   }
  //   if (playerEl.current) {
  //     playerEl.current.addEventListener('play', onPlay)
  //     return () => {
  //       playerEl.current.removeEventListener('play', onPlay)
  //     }
  //   }
  // }, [playerEl])

  useEffect(() => {
    const nodeCount = 2
    const nodes = [1, 4]
    const nodeIndex = Math.floor(Math.random() * nodeCount)
    const node = nodes[nodeIndex]
    let player

    let initPlayer = () => {
      const cacheBuster = 1
      const playerConfig = {
        id: 'playerjs',
        label: videoId,
        file: `https://node-${node}.cdn.jensen.nl/${videoId}/master.m3u8?cb=${cacheBuster}`,
      }
      if (previewImage?.fixed?.src) {
        playerConfig.poster = innoImg(previewImage.fixed.src)
      }

      let enableP2P =
        !window.localStorage.getItem('disable-p2p') &&
        p2pml.hlsjs.Engine.isSupported()

      // Disable P2P
      enableP2P = false

      // if (!enableP2P) {
      //   setP2PEnabled(false)
      // }

      if (enableP2P) {
        var engine = new p2pml.hlsjs.Engine({
          swarmId: videoId,
          segments: {
            // number of segments to pass for processing to P2P algorithm
            forwardSegmentCount: 15,
          },
          loader: {
            // useP2P: false,
            trackerAnnounce: ['wss://wt-tracker.jensen.nl'],

            // how long to store the downloaded segments for P2P sharing
            cachedSegmentExpiration: 86400000,
            // count of the downloaded segments to store for P2P sharing
            cachedSegmentsCount: 100,

            // first 8 segments (priorities 0, 1, 2 and 3) are required buffer for stable playback
            requiredSegmentsPriority: 10,

            // each 1 second each of 20 segments ahead of playhead position gets 6% probability for random HTTP download
            httpDownloadMaxPriority: 10,
            httpDownloadProbability: 0.06,
            httpDownloadProbabilityInterval: 1000,

            // disallow randomly download segments over HTTP if there are no connected peers
            httpDownloadProbabilitySkipIfNoPeers: true,

            // P2P will try to download only first 51 segment ahead of playhead position
            p2pDownloadMaxPriority: 15,

            // 1 second timeout before retrying HTTP download of a segment in case of an error
            httpFailedSegmentTimeout: 1000,

            // enable mode, that try to prevent HTTP downloads on stream start-up
            // try to prevent HTTP downloads during first 2 minutes
            // httpDownloadInitialTimeout: 120000,
            // try to prevent HTTP download per segment during first 17 seconds
            // httpDownloadInitialTimeoutPerSegment: 2000,

            // number of simultaneous downloads for P2P and HTTP methods
            simultaneousP2PDownloads: 1,
            simultaneousHttpDownloads: 1,

            // allow to continue aborted P2P downloads via HTTP
            httpUseRanges: true,
          },
        })
        playerConfig.hlsconfig = {
          loader: engine.createLoaderClass(),
        }

        // engine.on('segment_loaded', (segment, peerId) => {
        //   console.log(segment.url, segment.downloadBandwidth, peerId)
        // })
      }
      player = new Playerjs(playerConfig)
      if (enableP2P) {
        p2pml.hlsjs.initHlsJsPlayer(player.api('hls'))
      }
    }

    if (typeof window !== 'undefined' && !window.Playerjs) {
      ;(async () => {
        // Load async
        let promise = loadScript('/playerjs.5.js')

        // Load sync, core is needed for hlsjs
        await loadScript(
          'https://cdn.jsdelivr.net/npm/p2p-media-loader-core@latest/build/p2p-media-loader-core.min.js'
        )
        await loadScript(
          'https://cdn.jsdelivr.net/npm/p2p-media-loader-hlsjs@latest/build/p2p-media-loader-hlsjs.min.js'
        )

        // Make sure player is loaded in the meantime as well
        await promise
        initPlayer()
      })()
    } else {
      initPlayer()
    }

    return () => {
      player.api('destroy')
    }
  }, [])

  return (
    <Player>
      <div id="playerjs" ref={playerEl} />
    </Player>
  )
}

export default PlayerBlock
